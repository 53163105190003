export const s001 = "SQUID GAME: GLASS BRIDGE";
export const s002 = "By Adam Reeves";
export const s003 = "allWinners";
export const s004 = "";
export const s005 = "easy";
export const s006 = "medium";
export const s007 = "hard";
export const s008 = "Main Menu";
export const s009 = "text";
export const s010 = "Enter Player Name";
export const s011 = "submit";
export const s012 = "Start Game";
export const s013 = "Game Winners";
export const s014 = "playerProfile";
export const s015 = "player";
export const s016 = "difficulty";
export const s017 = "answers";
export const s018 = "SquidGameRemix.mp3";
export const s019 = "Music";
export const s020 = "How to Play";
export const s021 = "Reset Game";
export const s022 = "Profile";
export const s023 = "Mode";
export const s024 = "Lives";
export const s025 = "x";
export const s026 = "The database is temporarily offline for maintainence";
export const s027 = "It should be back online tomorrow.";
export const s028 = "Back";
export const s029 = "LOADING...";
export const s030 = "Finish";
export const s031 = "Start Here";
export const s032 =
  "To win the game you must cross the glass bridge. As you move, you must choose the left or right path. If you choose correctly, the glass tile will hold your weight (tile turns green) and you can continue to cross. If you choose wrong, the glass tile will break (tile turns red and you start back atthe beginning). If you run out of lives, you will fall to your death and the game is over. Start at the bottom row. Good luck ";
export const s033 = "!";
export const s034 = "Back to the Game";
export const s035 = "/";
export const s036 = "Players";
export const s037 = "0";
export const s038 = "1";
export const s039 = "Character";
export const s040 = "Player Profile";
export const s041 = "radio";
export const s042 = "selectedDifficulty";
export const s043 = "Difficulty";
export const s044 = "Easy";
export const s045 = "Medium";
export const s046 = "Hard";
export const s047 = "YOU FELL TO YOUR DEATH!";
export const s048 = "Try Again";
export const s049 = "GAME OVER";
export const s050 = "selectedGamePiece";
export const s051 = "No one has beat this mode yet";
export const s052 = "Top Game Winners";
export const s053 = "Back to Menu";
export const s054 = "Refresh";
export const s055 = "Hard mode";
export const s056 = "Medium mode";
export const s057 = "Easy mode";
export const s058 = "YOU WON THE SQUID GAME";
export const s059 = "on ";
export const s060 = "Play Again";
export const s061 = "Player's Game Piece";
export const s062 = "Back to Game";
export const s063 = "profile__bodyRow";
export const s064 = "Games played on ";
export const s065 = "Games won on ";
export const s066 = "Easy:";
export const s067 = "Medium:";
export const s068 = "Hard:";
export const s069 = "Total games played:";
